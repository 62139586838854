.footer {
  background: #5B2A81;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;

  .footer_list {
    font-size: 15px;
    display: flex;
    justify-content: center;
    padding: 0;
    flex-wrap: wrap;

    li {
      list-style: none;

      a, div {
        padding: 0 20px;
        border-right: 1px solid #fff;
        color: #fff;
      }

      &:last-child a{
        border-right: none;
      }
    }
  }

  .footer_list.large {
    li {
      a {
        padding: 0 20px;
      }
    }
  }

  .footer_list.small {
    li {
      a {
        padding: 0 10px !important;
      }
    }
  }
}

@media screen and (max-width: 500px){
  .footer .footer_list {
    font-size: 15px;
    display: block;
    justify-content: center;
    padding: 0;
    flex-wrap: wrap;
    text-align: center;

    li {
      list-style: none;

      a, div {
        padding: 0 20px;
        border-right: none;
        color: #fff;
        margin-bottom: 10px;
      }

      &:last-child a{
        border-right: none;
      }
    }
  }
}