._masonry {
  column-count: 1;
  column-gap: 30px;

  @media (min-width: 768px) {
    column-count: 2;
  }

  ._item {
    background: #fff;
    margin-bottom: 30px;
    display: block;           // Change from inline-block to block
    break-inside: avoid;      // Prevent items from breaking between columns
    width: 100%;
    padding: 30px;
    border: 1px solid #ededed;
  }
}


._restaurant-image{
  width: 100%;
}

._restaurant-item{
  ._banner{
    display: flex;
    justify-content: space-between;
    padding: 30px;
    background-position: center;
    background-size: cover;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;

    
    
    h2{
      text-transform: uppercase;
      color: #fff;
      margin-bottom: 0;
      width: 100%;
      @media(min-width: 768px){
        width: auto;
      }
      @media(min-width: 1036px){
        max-width: 50%;
      }
      
    }
    img{
      width: 100%;
    }
    ._book-here{
      color: #fff!important;
      background: #D6B052 !important;
      border: 2px solid #D6B052;
      margin: 0;
      &:hover{
        box-shadow: 0px 5px 15px rgb(29, 7, 47);
      }
    }
  }

  p{
    margin: 30px 0;
    a{
      color: inherit;
      &:hover{
        text-decoration: underline;
      }
    }
  }

  ._social-media{
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 30px 0;
    a{
      background-color: #FAFAFA;
      color: inherit;
      font-size: 12px;
      padding: 5px 10px;
      border-radius: 20px;
      display: flex;
      gap: 5px;
      align-items: center;
    }
  }
}

._heading-container{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  margin-bottom: 30px;
  h2{
    font-weight: 600 !important;
    margin-bottom: 0;
  }
  
}

._buttons{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-end;
  ._book-here{
    color: #fff!important;
    background: #5B2B81 !important;
    border: 2px solid #5B2B81;
  }
  ._share{
    color: #5B2B81!important;
    border: 2px solid #5B2B81; 
    &.hover{
      background-color: #fff;
    }
  }
}

._item-content{
  p{
    a{
      color: inherit;
    }
  }
  ._divider{
    width: 1px;
    height: 15px;
    background-color: #EDEDED;
    margin: 0 10px;
    display: inline-block;
  }
}

._purple-underline{
  color: #5B2B81!important;
  text-decoration: underline;
}

._wines-avail{
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  img{
    height: 100px;
  }
}

._cultivar-tag{
  background-color: #EDEDED;
  border-radius: 100px;
  border: 1px solid #EDEDED;
  height: 38px;
  padding: 15px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

