._advanced-filter{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    margin-bottom: 60px;
   
    @media(min-width:800px){
        flex-wrap: nowrap;
        // ._search{
        //     max-width: 570px;
        // }
    }
    @media(min-width:1100px){
        flex-wrap: nowrap;
        // ._search{
        //     max-width: 570px;
        // }
        ._region{
            max-width: 370px;
        }
    }
    ._region{
        border: 1px solid #EDEDED;
        width: 100%;
        height: 38px;
        background-color: #fff;
        padding: 0 10px;
        select{
            background-color: transparent;
            border: none;
            width: 100%;
            height: 100%;
            padding-top: 0!important;
        }
    }
    ._search{
        border: 1px solid #EDEDED;
        width: 100%;
        height: 38px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        ._icon{
            width: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg{
                transform: scale(0.65);
            }
        }
        input{
            background-color: transparent;
            border: none;
            width: 100%;
            height: 100%;
            padding-top: 0!important;
        }
        ._reset{
            background-color: transparent;
            border: none;
            width: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg{
                transform: scale(0.8);
            }
        }
    }
    ._apply-btn{
        background-color: #5B2B81;
        color: #fff;
        padding: 13px 30px 12px 30px;
        margin: 0;
        white-space: nowrap;
    }

    ._apply-btn:hover{
        color: #5B2B81;
        background-color: #fff;
        border: 1px solid #5B2B81; /* Added border for visibility */
    }

    ._trade-btn {
        color: #5B2B81;
        background-color: #fff;
        padding: 13px 30px 12px 30px;
        margin: 0;
        white-space: nowrap;
        border: 1px solid #5B2B81;
        transition: background-color 0.3s, color 0.3s;
        opacity: 1; /* Ensures full opacity */
    }
    
    ._trade-btn:hover {
        color: #fff;
        background-color: #5B2B81;
        opacity: 1; /* Maintains full opacity on hover */
    }
    
    
}