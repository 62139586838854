.sliderHeader-merlot{
  max-width: calc(100% - 40px);
  margin: 0 auto;

  @media only screen and (min-width: 801px){
    max-width: 50%;
    margin-left: 0;
  }
  @media only screen and (min-width: 1101px){
    max-width: calc(100% - 580px);
    padding-left: 15px;
  }
  @media only screen and (min-width: 1370px){
    max-width: 570px;
  }
}

._merlot-banner{
  background-position: left center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  position: relative;
  @media(min-width: 801px){
    background-position: center;
  }
  @media only screen and (min-width: 1101px){
    background-position: right center;
  }
  @media only screen and (min-width: 1370px){
    background-position: center;
  }
}

.sliderHeader h2._merlot-heading{
  font-size: 60px;
  line-height: 50px!important;
  margin-bottom: 15px;
  strong{
    font-weight: 600;
  }
}

.sliderHeader p._merlot-message{
  font-size: 24px;
  line-height: 32px;
  color: #000;
  strong{
    font-weight: 600;
  }
}

.slick-slide img._merlot-bottle{
  position: absolute;
  right: 422px;
  top: 30px;
  width: 200px;
  display: none;
  @media only screen and (min-width: 1101px){
    display: block;
  }
  @media only screen and (min-width: 1370px){
    right: unset;
    left: 55%;
  }
}