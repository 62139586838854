
.wine-items {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

}
.wine-item {
  display: flex;
  align-items: flex-end;
  margin-bottom: 50px;
  max-width: 33%;
}

.wine-item.centered {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  max-width: 33%;
}

 .wine-item-subtitle {
   margin-bottom: 10px;
   min-height: 85px
 }

.wine-devider {
  width: 100px;
  background: #ccc;
  height: 2px;
  margin: 30px 0;
}

.wine-right {
  padding-bottom: 34px;
}
.wine-left {
  text-align: center;
}

.wine-left img{
  width: 160px;
}

.wine-right h3 {
  font-size: 20px;
  text-transform: 'uppercase';
}

.chardonnay {
  color: #5A720F;
}

.suavignon-blanc {
  color: #097379
}

.chenin-blanc {
  color: #5A720F
}

.rose {
  color: #CBA3AB
}

.pinotage {
  color: #763E5B
}

.cabernet-sauvignon {
  color: #6D002C
}

.merlot {
  color: #62045C
}

.shiraz {
  color: #B80235
}

.the-golden-reserve {
  color: #763E5B
}

@media screen and (max-width: 1250px) { 
  .wine-left img {
    width: 130px;
 
  }

  .wine-item {
    max-width: 47%;
  }
}


@media screen and (max-width: 1100px) { 
  .wine-left img {
    width: 130px;
  }
}

@media screen and (max-width: 768px) { 
  .wine-item {
    display: block;
    max-width: 367px;
    margin: 0 auto;

    .wine-item-subtitle {
      min-height: 70px;
    }

    .wine-left img {
      width: 190px;
    }

    .wine-right {
      padding-bottom: 10px;
    }
  }


  .wine-left img {
    margin: 0 auto;
  }

  .wine-slider {
    .slick-slider {
      overflow: hidden;
    }
  }

  .wine-devider {
    width: 100%;
    margin: 10px 0;
  }

  .wine-slider {
    margin-top: 60px;
  }
}


