

.ui.selection.active.dropdown .menu {
  border-radius: 0 !important;
  margin: 1px -1px;
  border: 2px solid #ccc
}
.dropdown .icon {
  font-size: 30px !important;
  padding: 8px !important;
}
.ui.dropdown .menu {
  border-radius: 0;
  top: 102%;
  min-width: calc(100% + 4px) !important;
  width: calc(100% + 3px) !important;
  
  width: auto !important;
  max-width: auto !important;

  left: -1px !important;

  .item {
    font-size: 16px;
  }
}

.ui.selection.active.dropdown:hover .menu {
  border: 2px solid #ccc
}

.btnArrow.purple {
  color: #fff;
  background: #5B2B81 !important;
  border: 2px solid #5B2B81;
  
  &:hover {
    background: transparent !important;
    color: #5B2B81;
  }
}

.ui.selection.dropdown.chardonnay {
  color: #fff !important;
  text-transform: uppercase;
  background: #5B2B81 !important;
  border: 2px solid #5B2B81 !important;
  padding: 20px 30px 16px 30px;
  border-radius: 0;
  
  .default.text {
    color: #fff !important;
  }
  
  &:hover {
    background: #fff !important;
    color: #5B2B81 !important;

    .default.text {
      color: #5B2B81 !important;
    }
  }
}

.ui.selection.dropdown.chardonnay {
  color: #fff !important;
  text-transform: uppercase;
  background: #5A720F !important;
  border: 2px solid #5A720F !important;
  padding: 20px 30px 16px 30px;
  border-radius: 0;
  
  .default.text {
    color: #fff !important;
  }
  
  &:hover {
    background: #fff !important;
    color: #5A720F !important;

    .default.text {
      color: #5A720F !important;
    }
  }
}


.ui.selection.dropdown.suavignon-blanc {
  color: #fff !important;
  text-transform: uppercase;
  background: #097379 !important;
  border: 2px solid #097379 !important;
  padding: 20px 30px 16px 30px;
  border-radius: 0;
  
  .default.text {
    color: #fff !important;
  }
  
  &:hover {
    background: #fff !important;
    color: #097379 !important;

    .default.text {
      color: #097379 !important;
    }
  }
}

.ui.selection.dropdown.chenin-blanc {
  color: #fff !important;
  text-transform: uppercase;
  background: #5A720F !important;
  border: 2px solid #5A720F !important;
  padding: 20px 30px 16px 30px;
  border-radius: 0;
  
  .default.text {
    color: #fff !important;
  }
  
  &:hover {
    background: #fff !important;
    color: #5A720F !important;

    .default.text {
      color: #5A720F !important;
    }
  }
}

.ui.selection.dropdown.rose {
  color: #fff !important;
  text-transform: uppercase;
  background: #CBA3AB !important;
  border: 2px solid #CBA3AB !important;
  padding: 20px 30px 16px 30px;
  border-radius: 0;
  
  .default.text {
    color: #fff !important;
  }
  
  &:hover {
    background: #fff !important;
    color: #CBA3AB !important;

    .default.text {
      color: #CBA3AB !important;
    }
  }
}

.ui.selection.dropdown.pinotage {
  color: #fff !important;
  text-transform: uppercase;
  background: #763E5B !important;
  border: 2px solid #763E5B !important;
  padding: 20px 30px 16px 30px;
  border-radius: 0;
  
  .default.text {
    color: #fff !important;
  }
  
  &:hover {
    background: #fff !important;
    color: #763E5B !important;

    .default.text {
      color: #763E5B !important;
    }
  }
}

.ui.selection.dropdown.cabernet-sauvignon {
  color: #fff !important;
  text-transform: uppercase;
  background: #6D002C !important;
  border: 2px solid #6D002C !important;
  padding: 20px 30px 16px 30px;
  border-radius: 0;
  
  .default.text {
    color: #fff !important;
  }
  
  &:hover {
    background: #fff !important;
    color: #6D002C !important;

    .default.text {
      color: #6D002C !important;
    }
  }
}

.ui.selection.dropdown.merlot {
  color: #fff !important;
  text-transform: uppercase;
  background: #62045C !important;
  border: 2px solid #62045C !important;
  padding: 20px 30px 16px 30px;
  border-radius: 0;
  
  .default.text {
    color: #fff !important;
  }
  
  &:hover {
    background: #fff !important;
    color: #62045C !important;

    .default.text {
      color: #62045C !important;
    }
  }
}

.ui.selection.dropdown.shiraz {
  color: #fff !important;
  text-transform: uppercase;
  background: #B80235 !important;
  border: 2px solid #B80235 !important;
  padding: 20px 30px 16px 30px;
  border-radius: 0;
  
  .default.text {
    color: #fff !important;
  }
  
  &:hover {
    background: #fff !important;
    color: #B80235 !important;

    .default.text {
      color: #B80235 !important;
    }
  }
}

.ui.selection.dropdown.the-golden-reserve {
  color: #fff !important;
  text-transform: uppercase;
  background: #763E5B !important;
  border: 2px solid #763E5B !important;
  padding: 20px 30px 16px 30px;
  border-radius: 0;
  
  .default.text {
    color: #fff !important;
  }
  
  &:hover {
    background: #fff !important;
    color: #763E5B !important;

    .default.text {
      color: #763E5B !important;
    }
  }
}


@media screen and (max-width: 760px) {
  .ui.selection.dropdown {
    width: 100%;
  }
}