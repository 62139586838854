.vib {
  display: flex;
  justify-content: space-between;

  .vib-card {
    width: 49%;
    align-items: center;
    margin-bottom: 100px;
    padding: 40px;
    color: #fff;

    h3 {
      font-size: 30px;
      color: #fff;
    }
  }

  .vib-devider{
    width: 100px;
    background:#5B2B81;
    height: 2px;
    margin: 30px 0;
  }
  
}

@media screen and (max-width: 1050px) {
  .vib {
    display: block;

    .vib-card {
      width: 100%;
      margin-bottom :20px;

      &:last-child {
        margin-bottom: 60px;
      }
    }
  } 
}
