.hr_covid-19 {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1px;
	flex-wrap: wrap;
	text-align: center;
	// position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
    background-color: #fff;
    p{
        color:rgba(0,0,0,.87)
    }
}

.hr_covid-19-agegate{
    display: flex;
	justify-content: center;
	align-items: center;
	padding: 1px;
	flex-wrap: wrap;
    text-align: center;
    color: #fff;
}