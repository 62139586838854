
 input#mobile {
   padding-left: 60px;
   position: relative;
   display: block;
 
 }

 input {
   padding-top: 10px !important;
   font-size: 16px !important;
 }

 
select {
  padding-top: 7px !important;
  font-size: 16px !important;
  cursor: pointer;
}

 .form-group.mobile:before {
  content: "(+27)";
  position: absolute;
  top: 8px;
  left: 15px;
  height: 20px;
  width: 40px;
  border-right: 1px solid #ccc;
  z-index: 100;
  color: 212529;
  display: block;
  padding-right: 10px;
  font-size: 14px !important;
}

.vib-r-content {
  display: flex;
  align-items: center;
  .content-box {
    width: 50%;
    display: flex;
    flex-direction: column;
   
    justify-content: left;
    
    h2.title {
      color: #D6B052 !important;
      font-size: 55px;
      margin: 0;
      line-height: 1;
      font-weight: 600;
      position: relative;
      margin-bottom: 40px;
      width: 100%;
      &:after {
        content: '';
        width: 100px;
        height: 2px;
        background: rgba(117, 117, 117, 0.767);
        position: absolute;
        top: 120%;
        left: 0;
      }
    }

    img {
      width: 100%
    }
  }
}
.vib-restarurant-box {
  margin: 100px 0
}
.gold_bold {
  color: #D6B052;
}

.checkBoxContainer {
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
  align-items: center;
  position: relative;
  padding-left: 51px;
  height: 50px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkBoxContainer input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 40px;
  border: 1px solid #eee;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.checkBoxContainer:hover input[type='checkbox'] ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkBoxContainer input[type='checkbox']:checked ~ .checkmark {
  background-color: #D6B052;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkBoxContainer input[type='checkbox']:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkBoxContainer .checkmark:after {
  left: 17px;
  top: 10px;
  width: 8px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*  */


/* Customize the label (the container) */
.radioContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radioContainer input[type='radio'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.radioCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radioContainer:hover input[type='radio'] ~ .radioCheckmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radioContainer input[type='radio']:checked ~ .radioCheckmark {
  background-color: #C40076;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radioContainer:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioContainer input[type='radio']:checked ~ .radioCheckmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radioContainer .radioCheckmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.live_large {
  line-height: 1.5 !important
}

@media screen and (max-width: 770px){

  .vib-r-content {
    flex-direction: column-reverse;
    
    .content-box {
      width: 100%;

      h2.title {
        margin-top: 20px;
        font-size: 30px;

        &:after {
          top: 153%;
        }
      }
    }
  }
  .radioContainer {
    text-align: left;
  }

  label {
    width: 100%;
    text-align: left;
  }
}