.wine-detail-display {
  display: flex;
  align-items: center;
  padding: 60px 0;

  .wine-detail-panel {
    width: 50%;
  }

  .wine-detail-content {
    h2 {
      font-size: 55px;
    }
  }

  .wine-detail-image{
    text-align: center;
    position: relative;


    .wine-img-underlay {
      z-index: -20;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      font-size: 100px;
      line-height: -5px;
      padding: 41px 0;

      p {
        line-height: 1;
        width: 75%;
        color: rgba(204, 204, 204, 0.123);
        margin: 0;
        letter-spacing: 42px;
      }
    }

    img {
      max-width: 280px;
      z-index: 40;
    }
  } 
.MsoNormal {
  line-height: 1.5 !important;
}
  .wine-detail-content {
    h1 {
      font-size: 55px;
      padding: 0 0 20px 0;
      margin-bottom: 25px;
      border-bottom: 1px solid #4e4b4b;
    }

    .wine-detail-subtitle {
      font-size: 25px;
    }
  }
}


@media screen and (max-width: 1230px) {
  .wine-detail-display .wine-detail-image .wine-img-underlay p {
    letter-spacing: 27px;
  }
}

@media screen and (max-width: 860px) {
  .wine-detail-display {
    display: block;

    .wine-detail-panel {
      width: 100%;
    }

    .wine-detail-image {
      .wine-img-underlay {
        font-size: 55px;
      }
    }
  }
}

.fb_shopnow-btn{
  margin: 10px auto;
  @media (min-width: 769px){
    margin: -5px 10px 0 0;
  }
}

.fb_youtube-cont{
  margin: 60px auto;
  width: 700px;
  max-width: 100%;
}