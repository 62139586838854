.bottle-footer {
  display: block;
  width: 100%;
}
.poem {
  text-align: center;
  font-size: 20px;
  padding-bottom: 100px;

  span {
    color: #D6B052
  }
}