


@import url('https://use.typekit.net/oew6dtc.css');

body {
  margin: 0;
  font-size: 18px !important;
  font-family: 'minion-pro', 'Crimson Text', serif !important;
  scroll-behavior: smooth !important;
}

b, strong {
  font-weight: normal;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

::-webkit-input-placeholder { /* Edge */
  color: #b3b3b3 !important
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #b3b3b3 !important
}

::placeholder {
  color: #b3b3b3 !important
}

.scroll-to-top-arrow {
  position: fixed;
  bottom: 100px;
  right: 50px;
  font-size: 30px;
  color: #fff;
  z-index: 300;
  cursor: pointer;
  border-radius: 50%;
  font-size: 24px;
  width: 45px;
  height: 45px;
  background: #D6B052;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: box-shadow 180ms;
  border: 2px solid #fff;
}

.scroll-to-top-arrow:hover {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

h1, h2, h3, h4 {
  font-family: 'minion-pro', 'Crimson Text', serif !important;
  font-weight: normal !important;
}

h2 {
  line-height: 1.05 !important;
}

p, ul li {
  line-height: 1.5
}

h2 p{
  line-height: 1.05 !important;
}

button, input, optgroup, select, textarea {
  font-family: 'minion-pro', 'Crimson Text', serif !important;
  font-size: 14px;
}

.ui.container {
  margin: 0 auto !important;
  width: 100% !important;
  position: relative;
}

#root {
  padding-top: 167px;
  overflow-x: hidden;
}

.ui.container {
  max-width: 1250px !important;
}

.container-small {
  max-width: 750px;
  margin: 0 auto
}

.container-extra-small {
  max-width: 550px;
  margin: 0 auto
}

.inline-button-group {
  margin-top: 20px;
  display: flex;
}

.inline-button-group div {
  margin-right: 20px;
}

.form-header {
  color: #D6B052;
  font-size: 40px
}

@media screen and (max-width: 1100px) { 
  #root {
    padding-top: 105px;
  }

  .container-small {
    max-width: 93%;
  }

  .container-extra-small {
    max-width: 93%;
  }
}



@media screen and (max-width: 760px) { 
  .scroll-to-top-arrow {display: none;}
  
  h2 {
    font-size: 23px !important;
  }
 h2:after {
    top: 139% !important;
  }

  h2.title:after {
    top: 139% !important;
  }
  
  .form-header {
    font-size: 25px !important
  }
}

._text-gold{
  color: #D6B052
}