.restaurant-detail {
  .restaurant-container {
    padding: 120px 0 0 0;
    display: flex;

    .restarurant-box {
      width: 50%;

      img {
        width: 100%;
      }
    }
  }

  .website_links a {
    color: rgba(0, 0, 0, .87)
  }

  .restaurant-gallery {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    div {
      width: 24%;

      img {
        width: 100%;
        // height: 208px;
      }
    }
  }

  h2.title {
    color: #D6B052 !important;
    font-size: 44px;
    margin: 0;
    line-height: 1;
    font-weight: 600;
    position: relative;
    margin-bottom: 40px;

    &:after {
      content: '';
      width: 100px;
      height: 2px;
      background: rgba(117, 117, 117, 0.767);
      position: absolute;
      top: 110%;
      left: 0;
    }
  }

  .MsoNormal {
    line-height: 1.7 !important;
  }

  h3 {
    margin-bottom: 0;
  }

  .gold {
    color: #D6B052
  }


  .social-icons {
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .social-icon-list {
      width: 200px;
      display: flex;
      justify-content: left;
      padding: 0;
      position: relative;

      li {
        list-style: none;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #D6B052;
        margin: 0 10px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          display: block;
          padding: 10px;
          font-size: 20px;
          color: #fff
        }
      }
    }
  }

  .social-icon-devider {
    width: 36%;
    height: 1px;
    background: yellow;
  }

  @media screen and (max-width: 960px) {
    .social-icon-devider {
      width: 32%;
    }
  }


  @media screen and (max-width: 768px) {

    .restaurant-gallery {
      flex-direction: column-reverse;

      div {
        width: 100% !important;
        margin-bottom: 20px;

        img {
          height: auto;
        }
      }
    }

    .restaurant-detail {
      h2.title {
        font-size: 40px !important;
      }
    }

    .social-icon-devider {
      width: 25%;
    }
  }

  @media screen and (max-width: 480px) {
    .social-icon-devider {
      width: 20%;
    }
  }
}

._recipe-overview{
  @media(min-width: 961px){
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 30px;
  }
}