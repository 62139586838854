form {
  margin-bottom: 30px;
  text-align: center;
  @media(min-width: 800px){
    margin-bottom: 60px;
  }
}

.register-form {
  margin-bottom: 100px;
}

.centered {
  margin: 0 auto;
  text-align: center;
}

.input-error {
  text-align: left !important;
  color: red;
}

.input-error {
  text-align: left !important;
  color: red;

  &.centered {
    text-align: center !important;
  }
}

.ui.checkbox {
  margin: 20px 0;
}

.ui.form textarea,
.ui.form input:not([type]),
.ui.form input[type=date],
.ui.form input[type=datetime-local],
.ui.form input[type=email],
.ui.form input[type=file],
.ui.form input[type=number],
.ui.form input[type=password],
.ui.form input[type=search],
.ui.form input[type=tel],
.ui.form input[type=text],
.ui.form input[type=time],
.ui.form input[type=url] {
  border-radius: 0;
  padding: 12px;
  font-size: 16px;
  color: #b3b3b3;
  border: 1px solid #b3b3b3 !important;
}

.g-recaptcha {
  display: flex;
  justify-content: center;
}

.g-recaptcha div {
  margin-left: 15px;
  margin-bottom: 20px;
  transform: scale(.92);
  transform-origin: 0 0;
  width: 100%;
}