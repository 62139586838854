

.social-icons {
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .social-icon-list {
    // width: 200px;
    display: flex;
    justify-content: center;
    padding: 0;
    position: relative;
  
    li {
      list-style: none;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #D6B052;
      margin: 0 10px;
  
      a {
        display: flex !important;
        justify-content: center;
        align-items: center;
        text-align: center;
      
        padding: 10px;
        font-size: 20px;
        color: #5B2A81;
      }
    }
  }
}

.social-icon-devider {
  width: 36%;
  height: 1px;
  background: yellow;
}

@media screen and (max-width: 960px) {
  .social-icon-devider {
    width: 32%;
  }
}


@media screen and (max-width: 768px) {
  .social-icon-devider {
    width: 25%;
  }
}

@media screen and (max-width: 480px) {
  .social-icon-devider {
    width: 20%;
    opacity: 0;
  }
}