._pagination-container{
    padding: 60px 0;
    ._pagination{
        margin: 0 auto;
        display: flex;
        justify-content: center;
        ._item{
            min-width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            padding: 0 15px;
            color: #B2B2B2;
            gap: 10px;
            &._active{
                background-color: #5B2A81;
                color: #fff;
            }
            &._enabled{
                color: #000;
            }
            
            &._next, &._prev{
                svg{
                    position: relative;
                    top: 2px;
                }
            }
        }
    }
}