
.social-feed {
  padding: 60px 0;
}

.social-cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap
}

.social-load-more {
  margin: 0 auto;
  cursor: pointer;
  display: block;
  font-size: 14px;
  padding: 16px 30px !important;
  transition: all 50ms;
  background: transparent;

  letter-spacing: 2px;
  color: #fff;
  background: #5B2B81 !important;
  border: 2px solid #5B2B81;

  &:hover {
    background: #fff !important;
    color: #5B2B81;
  }
  text-transform: uppercase !important;
 
}



