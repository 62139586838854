.wtb-container {
  text-align: center;

  .btn {
    margin-bottom: 40px;
  }
}
.wtb {
  height: 690px;
  position: absolute;
  width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.wtb-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(36, 36, 36, 0.966);
  z-index: 555;
}

.wtb-close {
  position: fixed;
  top: 200px;
  right: 100px;
  z-index: 556;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
}

@media screen and (max-width: 1959px){
  .wtb {
    height: 488px;
  
  }
}
