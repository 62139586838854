$yellow: #D6B052;

.age-gate-overlay {
  background: #fbf3f2f5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow-y: scroll;
}

.container {
  -ms-overflow-style: none;  // IE 10+
  scrollbar-width: none;  // Firefox
}
.age-gate-overlay::-webkit-scrollbar { 
  display: none;  // Safari and Chrome
}

.age-gate {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;

  display: flex;
  justify-content: center;
  box-shadow: 0px 5px 15px #dddddd;

  h3 {
    font-size: 16px;
    color: $yellow;
  }

  .age-gate-confirm {
    div form {
      button  {
       margin: 10px !important
      }
     }

    input {
      margin-right: 10px;
      cursor: pointer;
    }

    display: flex;
    font-size: 15px;
    align-items: center
  }
}

.age-confrim-checkbox {
  display: flex;
  align-items: center;

}

.age-gate-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50%;
}

.age-gate-left {
  background: #fff;
  width: 40%;
}

.age-gate-right {
  padding: 130px 80px;
  color: #fff;
  font-size: 25px;
  background: #5B2A81;
  width: 60%;

  .age-gate-link {
    font-size: 16px;

    a {
      color: $yellow;
      padding-left: 3px;
    }
  }

  span {
    color: #D6B052;
  }
}


@media screen and (max-width: 1000px){
  .age-gate {
    flex-direction: column
  }

  .age-gate-left, .age-gate-right {
    width: 100%;
  }

  .age-gate-left {
    padding: 20px;
  }

  .age-gate-right {
    padding: 20px;
  }

  .age-gate-box {
   height: 100%;
  }
}


@media screen and (max-width: 680px){
  .age-gate { 

    position: relative;
  }
  .age-gate-confirm {
    div form {
     margin: 0;
     display: flex;
     flex-direction: column;
     justify-content: center;

     button  {
      margin: 0 0 70px 0 !important;
     }
    }
  }
  
  .age-gate-right .age-gate-link {
    font-size: 12px;
  }

  .age-gate {
    h3 {
      font-size: 14px;
      color: $yellow;
      margin: 0;
    }
  }

  .age-gate-left {
    padding: 60px 20px;
  }

  .age-gate-right {
    font-size: 16px;
    padding: 60px 20px;

    p {
      margin-bottom: 5px !important;
    }
  }
}