.social-banner {
  background: #5B2B81;
  padding: 6px 0;
  display: flex;
  margin: 60px 0;
  
  .view-social-button {
    width: 500px;
    background-color: #5B2B81;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    color: #fff;
  
  }

  div {
    overflow: hidden;

    img {
      width: 100%;
      display: block;
      transition: opacity 180ms ease;
      backface-visibility: hidden;
    }
  }

  .social-link-copy {
    margin-bottom: 10px;

    font-size: 30px;
    line-height: 34px;
  }

  .social-link-icon {
    color: rgb(214, 176, 82);
  }
}

@media screen and (max-width: 768px) {

  .social-banner {
    display: flex;
    flex-wrap: wrap;
    margin: 120px 0;
    
    .view-social-button {
      width: 100%;
      background-color: #5B2B81;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      padding: 30px 10px;
      color: #fff;

      .social-link-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center
      }
    }

    .social-link-copy {
      font-size: 18px;
      line-height: 1.5;
      margin: 0;
    }

    div {

      &.social-banner-image {
        width: 100%;
      }
   
      overflow: hidden;

      img {
        display: block;
        transition: transform 180ms ease-in-out;
        cursor: pointer;
        

        &:hover {
          transform: scale(1.1)
        }
      }
    }
  }

  .social-link-copy, .social-link-icon {
   margin-left: 10px;
  }
}