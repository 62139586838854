.social-card {
  width: 23%;
  font-size: 15px;
  border: 1px solid #eee;
  transition: box-shadow 120ms;
  &:hover {
    box-shadow: 0px 3px 8px #dddddd;
  }
  margin-bottom: 60px;
  
  .social-card-content {
   
    padding: 15px;

    .preview-text {
      min-height: 80px;
    }

    .social-card-avatar {
      flex-grow: 1;
      display: flex;
      line-height: 18px;
      align-items: center;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .social-card-avatar-links {
        .instagram-home {
          font-size: 14px;
         
        }

        .instagram-post {
          font-size: 12px;
          color: #ccc;
        }
      }
    }

    .social-footer {
      display: flex;
      align-items: center;

      .social-card-links ul {
        display: flex;
        padding: 0;

        li {
          list-style: none;
          padding: 5px;
        }
      }
    }
  }

  a {
    color: #000;
  }
  img {
    width: 100%;
  }
}

@media (max-width: 1020px){
  .social-card {
    width: 48%;
  }
}

@media screen and (max-width: 560px){
  .social-card {
    width: 100%;

    .social-card-media {
      height: '350px'
    }
  }
}