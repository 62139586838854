.gallery-standalone {
  display: flex;
  flex-wrap: wrap;
  .gallery-item {
    width: 25%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      display: block;
      transition: 400ms ease;
      cursor: pointer;
      backface-visibility: hidden;

      &:hover {
       transform: scale(1.1);
      }
    }
  }
}

@media screen and (max-width: 768px) {
    .gallery-item {
      width: 100%;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
 
}

@media screen and (max-width: 480px) {
 
  
  .gallery-standalone {
    .gallery-item {
      width: 100%;
      overflow: hidden;
    }
  }
}

