$yellow: #D6B052;
.slick-slider {
  margin-top: 59px;
}

.home-slider {
  position: relative;
  .sliderItem {
    height: 430px;
    display: flex;
    align-items: center
  }
}


// .sliderHeader:after {
//   content: '';
//   width: 110px;
//   height: 1px;
//   background: #fff;
//   position: absolute;
//   top: 105%;
//   left: 0;
// }
.stuff {
  margin-top: -66px;
  width: 90px;
  height: 1px;
  background: #fff;
}

.sliderHeader {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  @media (min-width: 801px){
    text-align: left;
  }
}

// .sliderHeader br {
//   display: none;
//   @media (min-width: 801px){
//     display: block;
//   }
// }

.sliderHeader .slider-product-name {
  color: #fff;
  font-size: 16px;
  letter-spacing: 4px;
  display: inline-block;
  // margin-bottom: 15px;
}

.sliderHeader p {
  color: #fff;
  margin-top: 15px;
  font-size: 20px;
  fontSize: 1.1em;
  @media (min-width: 801px){
    fontSize: 1.3em;
  }
}

.sliderHeader h2 {
  color: #000;
  font-size: 60px;
  margin: 0;
  line-height: 1;
  font-weight: 600;
}

.slick-list {
  overflow: visible;
}

.slick-dots {
  bottom: -65px
}

.slick-dots li button:before {
  font-size: 20px;
  color: $yellow
}

.slick-arrow {
  border: 2px solid $yellow;
  width: 40px;
  height: 40px;
  background: #fff !important;

}

.slick-next:before, .slick-prev:before {
  line-height: 0 !important;
}
.slick-prev {
  z-index: 2;
  left: 10px;
}

.slick-next {
  right: 10px;
}

.slick-prev:before {
  content: url('../../resources/img/arrow-pointing-to-right.svg');;
  color: red;
  font-size: 20px;

}

.slick-next:before {
  content: url('../../resources/img/arrow-pointing-to-left.svg');;
  color: red;
  font-size: 20px;

}

.sliderImageOverlay {
  z-index: 9999;
  position: absolute;
  right: 0;
  // top: 24px;
  bottom: -19%;
  transform: translate(0, 0)
}

.sun {
  z-index: 9999;
  max-width: 350px;
}
.devices {
  max-width: 500px;
  margin-top: 25px;
}


.ChardsliderImageOverlay {
  z-index: 9999;
  position: absolute;
  right: 100px;
  top: 28px;
  transform: translate(0, 0)
}

.chard {
  z-index: 9999;
  max-width: 230px
}

.bottle {
  z-index: 9999;
  max-width: 232px;
  position: absolute;
  top: 0;
  left: 30%;
  transform: translate(-50%,-0)
}

@media screen and (max-width: 1200px) {
  .sliderImageOverlay {
    top: 10%;
  }

  .sun {
    max-width: 360px;
  }

  .bottle {
    max-width: 208px;
  }
}

@media screen and (max-width: 994px) {
 .sliderHeader h2 {
   font-size: 40px;
 }
}

@media screen and (max-width: 800px) {
  // .sliderHeader {
  //   width: 31%;
  // }

  .sun {
      max-width: 473px;
  }
  
  .bottle {
    left: 28%;
    top: 47px;
    max-width: 248px;
}

  .sliderImageOverlay{
    right: -240px;
  }
 }


 @media screen and (max-width: 768px) {
   .sliderHeader {
     width: 100%;
   }
   .sliderImageOverlay, .ChardsliderImageOverlay {
     display: none
   }
   .ChardsliderImageOverlay  {
    right: 0;
   }

  .SimpleSliderHeader p {
    font-size: 16px !important;
  }

  .stuff {
    display: none
  }

  .slider-product-name {
    text-align: center
  }

  .sliderHeader h2, .SimpleSliderHeader h2  {
    font-size: 29px !important ;
    text-align: center !important
  }

  .SimpleSliderHeader .slider-product-name {
    font-size: 14px !important
  }
 }

//golden ticket

.goldenTicket {
  z-index: 9999;
  max-width: 350px;
  display:none;
}

.gt_sliderImageOverlay {
	// display: block;
	bottom: 0;
	top: 60% !important;
	right: unset !important;
	left: -96px;
}
.gt_copy_1 {
	color: rgb(0, 0, 0);
	font-size: 41px;
	letter-spacing: 0px;
	font-weight: bold;
}
.sliderHeader h2.gt_copy_2{
  font-size: 25px !important;
  .span_1{
    font-style: italic;
  }
  .span_2{
    font-weight: bold;
  }
}

.sliderHeader p.gt_copy_3{
  font-size: 20px;
  color: #000;
  margin-top: 6px;
  line-height: 20px;
  .span_1{
    font-weight: bold;
  }
  .span_2{
    font-style: italic;
  }
  .span_3{
    font-weight: bold;
    font-style: italic;
  }
}

._dynamic-heading{
  p{
    color: #000!important;
  }
  strong{
    font-weight: 600;
  }
  ._one{
    font-size: 20px;
  }
  ._one a{
    color: #000!important;
    text-decoration: underline;
  }
  ._two, h1, h2{
    font-size: 30px;
    line-height: 30px;
  }
  ._three{
    font-size: 50px;
  }
}

.your_favourite_heading{
  
  strong{
    font-weight: 600;
  }
  
  ._one{
    font-size: 20px;
    // line-height: 50px;
  }
  ._two{
    font-size: 35px;
    // line-height: 45px;
  }
  ._three{
    font-size: 50px;
  }
}

.live_large_heading{
  width: 80%;
  margin: 0 auto;
  strong{
    font-weight: 600;
  }
  ._one{
    font-size: 20px;
    strong{
      font-size: 25px;
    }
  }
}



@media (min-width:769px){
  .gt_slider-product-name{
    margin-top: 10px;
  }
  .sliderHeader.gt_sliderHeader{
    width: 50%;
  }
  .gt_sliderImageOverlay {
    display: block;
    bottom: 0;
    top: 32% !important;
    right: 0 !important;
    left: unset;
  }
  .goldenTicket {
    max-width: 418px;
    display:block;
  }

  ._dynamic-heading{
    ._one{
      font-size: 22px;
    }
    ._two, h1, h2{
      font-size: 35px;
      line-height: 35px;
    }
    ._three{
      font-size: 60px;
    }
  }

  .your_favourite_heading{
    ._one{
      font-size: 25px;
    }
    ._two{
      font-size: 45px;
    }
    ._three{
      font-size: 60px;
    }
  }
  
}

@media( min-width: 800px){
  .live_large_heading{
    max-width: calc(100% - 320px);
    margin: 0;
    ._one{
      font-size: 23px;
      line-height: 26px;
      strong{
        font-size: 28px;
      }
    }
  }
  .live_large_heading_banner{
    width: 100%;
    max-width: 280px;
    margin: 0 70px;
  }
}

@media (min-width: 1101px){
  .gt_copy_1 {
    font-size: 72px;
  }
  .sliderHeader h2.gt_copy_2 {
    font-size: 42.5px !important;
  }
  .sliderHeader p.gt_copy_3 {
    font-size: 29.7px;
    color: #000;
    margin-top: 6px;
    line-height: 27px;
  }
  .goldenTicket {
    max-width: 600px;
  }
  .gt_sliderImageOverlay {
    display: block;
    bottom: 0;
    top: 10% !important;
    right: 0 !important;
    left: unset;
  }
  ._dynamic-heading{
    ._one{
      font-size: 24px;
    }
    ._two, h1, h2{
      font-size: 40px;
      line-height: 40px;
    }
    ._three{
      font-size: 80px;
    }
  }
  .your_favourite_heading{
    ._one{
      font-size: 30px;
      line-height: 50px;
    }
    ._two{
      font-size: 50px;
      line-height: 55px;
    }
    ._three{
      font-size: 80px;
      line-height: 65px;
    }
  }
  .live_large_heading{
    max-width: calc(100% - 450px);
    ._one{
      font-size: 25px;
      line-height: 32px;
      strong{
        font-size: 30px;
      }
    }
  }
  .live_large_heading_banner{
    width: 100%;
    max-width: 406px;
  }
}

._drink-wine-day-heading{
  h1{
    strong{
      font-weight: 600;
    }
  }
  p{
    color: #000;
    strong{
      font-weight: 600;
    }
  }
  @media(min-width:801px){
    padding-left: 20px;
    h1{
      font-size: 40px;
    }
    p{
      font-size: 24px;
    }
  }
  @media(min-width:1101px){
    padding-left: 0;
    h1{
      font-size: 50px;
    }
  }
}

.slider_link {
  color: #000;
  text-decoration-line: underline;
}

.slider_link:hover {
  color: #000;
  text-decoration-line: underline;
}