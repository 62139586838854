.dropdown-container {
  position: relative;

  .dropdown-nav {
    position: absolute;
    left: 0;
    top: 100%;
    width: 200px;
    background: #fff;
    z-index: 9;

    border: 1px solid #ccc;

    .item {
      background: rgba(236, 236, 236, 0.432);

      &:hover {
        background: #EBEBEB;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .dropdown-container .dropdown-nav {
    position: relative;
    width: 100%;
    border: none;
  }
}
