$yellow: #D6B052;
.centered {
  margin: 0 auto;
  text-align: center;
}
.btn {
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  padding: 16px 30px 12px 30px;
  transition: all 50ms;
  background: transparent;

  letter-spacing: 2px;
  border-radius: 0;
  max-width: 370px;
  margin: 0 auto;
  .inner  {
    display: flex;
    align-items: center;
    align-content: center;
  }

  span{
    display: inline-block;
  }

  .button-left {
    padding: 20px;
  }
  .button-right {
    padding: 20px 20px 19px;
    background: #DDBE71;
  }
  
  &:hover {
    opacity: .9;
    color: #fff;
    // background-color: #2EE59D;
    box-shadow: 0px 5px 15px rgb(221, 221, 221);
    color: #fff;
  }
}

.btn.purple {
  color: #fff;
  background: #5B2B81 !important;
  border: 2px solid #5B2B81;

  &:hover {
    background: #fff !important;
    color: #5B2B81;
  }
}

.btn.gold {
  color: #fff !important;
  background: #DDBE71 !important;
  border: 2px solid #DDBE71;

  &:hover {
    background: #fff !important;
    color: #DDBE71 !important;
  }
}


.btnRight {
  transition: all 180ms;
}

.btnArrow {
  transition: all 180ms;
}


.btnArrow.purple {
  color: #fff;
  background: #5B2B81 !important;
  border: 2px solid #5B2B81;
  
  &:hover {
    background: transparent !important;
    color: #5B2B81;
  }
}

.btn.chardonnay {
  border: 2px solid #5A720F;
  &:hover {
    background: #5A720F !important
  }
}

.btn.suavignon-blanc {
  border: 2px solid #097379;
  &:hover {
    background: #097379 !important
  }
}

.btn.suavignon-blanc {
  border: 2px solid #097379;
  &:hover {
    background: #097379 !important
  }
}

.btn.chenin-blanc {
  border: 2px solid #5A720F;
  &:hover {
    background: #5A720F !important
  }
}

.btn.rose {
  border: 2px solid #CBA3AB;
  &:hover {
    background: #CBA3AB !important
  }
}

.btn.pinotage {
  border: 2px solid #763E5B;
  &:hover {
    background: #763E5B !important
  }
}

.btn.cabernet-sauvignon {
  border: 2px solid #6D002C;
  &:hover {
    background: #6D002C !important
  }
}

.btn.merlot {
  border: 2px solid #62045C;
  &:hover {
    background: #62045C !important
  }
}

.btn.shiraz {
  border: 2px solid #B80235;
  &:hover {
    background: #B80235 !important
  }
}

.btn.the-golden-reserve {
  border: 2px solid #763E5B;
  &:hover {
    background: #763E5B !important
  }
}

.btnArrow {
  display: inline-block;
  line-height: 15px;
  font-size: 20px;
  padding: 20px 25px;;
  background: #DDBE71;
}

.btnRight {
  position: absolute;
  bottom: -28px;
  left: 0px;
  display: inline-block;
 
  font-size: 20px;

  .inner  {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
  }
  span{
    display: inline-block;
  }

  .button-left {
    padding: 20px;
  }
  .button-right {
    padding: 20px 20px 19px;
    background: rgb(201, 171, 96);
  }

  &:hover {
    background: rgb(209, 170, 72);
    color: #fff;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }
}

.yellow {
  background: $yellow;
  color: #fff;
}

.purple {
  background: #5B2A81;
  color: #fff;
  
}

.btnRight.purple {
  .button-right{
    background: #5B2A81;
  }
  &:hover{
    background: #5B2A81;
  }
}



@media screen and (max-width: 768px) {
  .btn {
    display: block;
    text-align: center;
  }
  
  .btnRight {
    left: 50%;
    width: 213px;
    transform: translateX(-50%);
    font-size: 16px;

  }
}
