$yellow: #D6B052;
.content {
  margin: 100px 0 60px 0;
}

.content-title {
  text-align: center;
  font-size: 55px;
  color: $yellow;
}

.content-subtitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}

.content-subtitle p{
   width: 48.5%;
   line-height: 28px !important;
}

.fullWidth p{
  width: 100%;
  line-height: 28px !important;
}

.centered {
  text-align: center;
  justify-content: center;
}

.disableFlex {
  display: block
}

.content-devider {
  width: 100px;
  background: #ccc;
  height: 2px; margin: 30px auto;
}

@media screen and (max-width: 800px) {
  .content-title {
    font-size: 44px;
  }
 }

 @media only screen and (max-width : 768px) {
  .content-subtitle {
    display: block
  }

  .content-subtitle p{
    width: 100%;
  }

  .content-devider {
    margin: 5px auto 22px auto;
  }
 }

 .fb_shop-btn{
  margin: 50px auto!important;
  @media (min-width: 769px){
    width: 300px;
    display: block!important;
  }
}