$yellow: #D6B052;
.slick-slider {
  margin-top: 20px;
}

.home-slider {
  position: relative;
}
.sliderItem {
  height: 320px;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .sliderItem {
    padding-top: 25px;
  }
}


// .sliderHeader:after {
//   content: '';
//   width: 110px;
//   height: 1px;
//   background: #fff;
//   position: absolute;
//   top: 105%;
//   left: 0;
// }

.SimpleSliderHeader .slider-product-name {
  color: #fff;
  font-size: 16px;
  letter-spacing: 4px;
  display: inline-block;
  margin-bottom: 15px;
}

.SimpleSliderHeader p {
  color: #fff;
  margin-top: 15px;
  font-size: 20px;
}

.SimpleSliderHeader h2 {
  color: $yellow !important;
  font-size: 55px;
  margin: 0;
  line-height: 1;
  font-weight: 600;
}


.leftaligned h2{
  text-align: left !important
}

.slider-subtitle {
  letter-spacing: 2px;
  max-width: 900px;
}

.slick-list {
  overflow: visible;
}

.slick-dots {
  bottom: -65px
}

.slick-dots li button:before {
  font-size: 20px;
  color: $yellow
}

.slick-arrow {
  border: 2px solid $yellow;
  width: 40px;
  height: 40px;
  background: #fff !important;

}

.slick-next:before, .slick-prev:before {
  line-height: 0 !important;
}
.slick-prev {
  z-index: 2;
  left: 10px;
}

.slick-next {
  right: 10px;
}

.slick-prev:before {
  content: url('../../resources/img/arrow-pointing-to-right.svg');;
  color: red;
  font-size: 20px;

}

.slick-next:before {
  content: url('../../resources/img/arrow-pointing-to-left.svg');;
  color: red;
  font-size: 20px;

}

.sun {
  max-width: 640px;
}
.devices {
  max-width: 400px;
  margin-top: 0;
}
@media (min-width: 947px){
  .devices {
    max-width: 550px;
  }
}
@media screen and (max-width: 1200px) {
  .sliderImageOverlay {
    top: unset;
    right: 0%;
    width: 46%;
    bottom: -13%;
  }

  .sun {
    max-width: 100%;
  }

  .bottle {
    max-width: 208px;
  }
}

@media screen and (max-width: 994px) {
 .SimpleSliderHeader h2 {
   font-size: 40px;
 }
}

@media screen and (max-width: 800px) {
  .SimpleSliderHeader {
    width: 100%;
  }

  // .sun {
  //     max-width: 473px;
  // }
  
  .bottle {
    left: 28%;
    top: 47px;
    max-width: 248px;
}

  .sliderImageOverlay{
    right: -240px;
  }

  // .slick-list {
  //   overflow: hidden;
  // }

  .sliderItem {
    height: 400px;
    display: flex;
    align-items: center
  }
 }



.fatherday{
  .sliderHeader {
    h2{
      @media(min-width: 800px){
        width: 40%;
        font-size: 28px;
      }
      @media(min-width: 1000px){
        font-size: 30px;
      }
      @media(min-width: 1100px){
        font-size: 34px;
      }
      @media(min-width: 1200px){
        font-size: 40px;
      }
    }
  }
  .sliderImageOverlay{
    @media(min-width: 800px){
      top: 10%;
      right: 0;
      .sun{
        max-width: 420px;
      }
    }    
    @media(min-width: 1000px){
      right: 3%;
      .sun{
        max-width: 500px;
      }
    } 
    
  }
}

.fb_strong{
  font-weight: bold;
}


.slick-slide{
  visibility:hidden;
}
.slick-slide.slick-active.slick-current{
  visibility:visible;
}

.live_large-heading{
  font-size: 35px!important;
  font-weight: 100!important;
  line-height: 35px;
  @media(min-width: 769px){
    font-weight: 300!important;
    font-size: 40px!important;
    line-height: 40px;
  }
  @media(min-width: 995px){
    font-weight: 300!important;
    font-size: 60px!important;
    line-height: 60px;
  }
  strong{
    font-weight: 900!important;
  }
  ._smaller-heading{
    font-size: 24px!important;
    @media(min-width: 769px){
      font-size: 34px!important;
    }
    @media(min-width: 995px){
      font-size: 40px!important;
    }
  }
}

.live-large-subheading{
  font-size: 20px;
  strong{
    font-weight: 900!important;
  }
  @media(min-width: 995px){
    font-size: 24px;
  }
}

.whiteWineSale{
  max-width: 315px; 
  @media(min-width: 900px){
    max-width: 360px;
  } 
  @media(min-width: 1101px){
    max-width: 460px;
  }
  @media(min-width: 1240px){
    max-width: 478px;
  }
}

.uranus{
  max-width: 370px; 
  @media(min-width: 900px){
    max-width: 452px;
  } 
  @media(min-width: 1101px){
    max-width: 525px;
  }
  @media(min-width: 1240px){
    max-width: 625px;
  }
}

.sliderImageOverlay_uranus{
  right: 65px;
  top: 80px;
  display:none;
  height: 400px;
  width: 420px;
  .inner{
    height: 100%;
    img{
      height: 100%;
    }
  }
  @media(min-width: 801px){
    display:block;
  }
  @media(min-width: 1100px){
    top: 40px;
    height: 430px;
  }
}

.sliderImageOverlay_uranus_top{
  right: 25px;
  top: 30px;
  display:none;
  height: 400px;
  width: 420px;
  .inner{
    height: 100%;
    img{
      height: 100%;
    }
  }
  @media(min-width: 801px){
    display:block;
  }
  @media(min-width: 1100px){
    top: 30px;
    height: 430px;
  }
}

.pluto{
  max-width: 370px; 
  @media(min-width: 900px){
    max-width: 420px;
  } 
  @media(min-width: 1101px){
    max-width: 430px;
  }
  @media(min-width: 1240px){
    // max-width: 625px;
  }
}

.sliderImageOverlay_pluto{
  right: -15px;
  top: 50px;
  display:none;
  @media(min-width: 801px){
    display:block;
  }
  @media(min-width: 1100px){
    top: 20px;
    right: 50px;
  }
}

.sliderHeader h2.winter_heading{
  img{
    width: 100%;
    max-width: 500px;
  }
  span{
    margin-top: 20px;
    display: block;
    max-width: 500px;
    text-align: left;
  }
  strong{
    font-weight: bold;
  }
  @media(min-width: 801px){
    width: 500px;
    font-size: 30px;
  }
  @media(min-width: 1100px){
    font-size: 40px;
  }
}