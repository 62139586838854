.form-container {
  padding-top: 120px;
}

.contact_links {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 80px;
  p {
    line-height: 35px !important;
    margin-bottom: 0;
    font-size: 17px;
  }
}