$yellow: #D6B052;

.recipes {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.restarurant-box p {
  margin-bottom: 0;
 
}
.recipe-box {
  margin-right: 10px;
  text-align: center;
  overflow: hidden;
  max-width: 24%;

  .recipe-overlay {
    opacity: 0;
    background: #000000ad;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 16px;
    width: 100%;
    height: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 150ms ease-in-out;
    border: 3px solid $yellow;
    cursor: pointer;
    backface-visibility: hidden;
  }

  h3 {
    margin-top: 15px;
    color: $yellow
  }

  .recipe-image-container {
    position: relative;

    &:hover {
      .recipe-overlay {
        opacity: 1;
        min-height: 100%;
      }
    }
  }

  &:last-child{
    margin-right: 0;
  }

  img {
    cursor: pointer;
    width: 100%;
    display: block;
    border: 1px solid #fff;
  }
}

.content {
  margin-top: 60px;
}

@media screen and (max-width: 1017px) {
  .recipe-box {
    max-width: 23%;
  }
}

@media screen and (max-width: 800px) {
 
  .recipe-box {
    max-width: 50%;
    width: 48%;
    flex-wrap: wrap;
    margin: 15px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 768px) {
  .recipe-slider {
    padding-bottom: 30px;
    
    .slick-slider {
      .slick-dots.slick-dots {
        bottom: 20px;
      }
    }
  }

  .recipes {
    flex-wrap: wrap;
    margin-bottom: 20px;

  }
  .recipe-box {
    max-width: 100%;
    width: 100%;
    flex-wrap: wrap;
    margin: 0;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 480px) {
 
  .recipe-box {
    max-width: 100%;
    margin: 0 0 80px 0
  }
}