.form-success {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 400px;
  margin-top: 160px;

  h2 {
    color: #D6B052;
    font-size: 40px;
  }
}